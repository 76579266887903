<template>
  <div>
    <reservation class="center" style="max-width: 800px; width: 94%" />

    <div style="padding: 10px">
      <slideshow
        style="max-width: 800px"
        class="center"
        :slides="slides"
      ></slideshow>
    </div>
    <div class="grid-container">
      <div class="main-content about-section">
        
        <IconWithText text="This page is under construction. For now, view the existing list of activities below." :icon="require('@/assets/warning.svg')"/>
        <div style="padding-top: 10px"></div>
        <iframe
          :srcdoc="htmlContent"
          width="100%"
          height="2600px"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
        ></iframe>
      </div>
    </div>

    <!-- <div
      class="grid-container"
      v-for="section in sections"
      :key="section.title"
    >
      <div class="main-content about-section">
        <h2>{{ section.title }}</h2>
        <TwoColumnTable :rows="section.rows" />
      </div>
    </div> -->
  </div>
</template>

<script>
import Reservation from "@/components/Reservation.vue";
import Slideshow from "@/components/Slideshow.vue";
import IconWithText from '../components/IconWithText.vue';

export default {
  name: "Rooms",
  components: {
    Reservation,
    Slideshow,
    IconWithText,
  },
  data() {
    return {
        htmlContent: `
<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
<html>
<head>
<meta http-equiv="Content-Type" content="text/html; charset=ISO-8859-1">
<meta name="Keywords" content="Mio Motel, Mio, Michigan, MI, au sable river,m lodging, accomodations, canoeing, kayaking, snowmobiling, fly fishing, trout fishing, ">
<meta name="msvalidate.01" content="E0C19F78C28398A992347CD28651D6C4" />

<meta name="google-site-verification" content="SYnheWcVADZBnF6NVM95-Yj1BZNEpoMAsZCTSkmsgUI" />

</head>
<body style="margin: 0px;" class="nof-centerBody">
  <div align="center">
    <table border="0" cellspacing="0" cellpadding="0">

          </table>
          <table border="0" cellspacing="0" cellpadding="0">
            <tr valign="top" align="left">
              <td height="1"></td>
            </tr>

                  <tr valign="top" style="height: auto;">
                    <td valign="top" id="Cell23">
                      <h3><b><span style="font-weight: bold;">Amish Community Businesses</span></b></h3>
                      <ul>
                        <li><span style="font-weight: normal;">Country Corners Bulk Food</span></li>
                        <li><span style="font-weight: normal;">Country Corners Greenhouse</span></li>
                        <li><span style="font-weight: normal;">Country Feed Supply</span></li>
                        <li><span style="font-weight: normal;">Highland Lumber</span></li>
                        <li><span style="font-weight: normal;">Home Style Bakery</span></li>
                        <li>Maple Ridge Metal</li>
                        <li>Miller Brothers Maple Products</li>
                        <li>Mio Buggy Shop</li>
                        <li>Nature&#8217;s Beauty Rustic Store</li>
                        <li>North Star Storage Buildings</li>
                        <li><span style="font-weight: normal;">Oak Hill Furniture &amp; Fabric</span></li>
                        <li><span style="font-weight: normal;">Pine Grove Woodworking</span></li>
                        <li><span style="font-weight: normal;">Sensenig&#8217;s Repair</span></li>
                        <li><span style="font-weight: normal;">Shady Lane Footware</span></li>
                        <li><span style="font-weight: normal;">Sweet Success Sugarbush</span></li>
                        <li><span style="font-weight: normal;">The Bakery</span></li>
                        <li><span style="font-weight: normal;">The Pioneer Store</span></li>
                        <li><span style="font-weight: normal;">Triple Heritage Farm</span></li>
                      </ul>
                      <h3><b><span style="font-weight: bold;">Attractions</span></b></h3>
                      <ul>
                        <li><a href="http://www.ausableriverqueen.com/">AuSable River Queen River Boat</a> &#8211; Oscoda</li>
                        <li><a href="https://www.facebook.com/pg/cedarvalleyswildfrontierfunpark/about/?ref">Cedar Valley;&#8217;s Wild Frontier Fun Park</a> - Mio</li>
                        <li>Fall Colors</li>
                        <li>Grandview School</li>
                        <li><a href="https://www.michigan.org/property/iargo-springs-interpretive-site">Iargo Springs</a> &#8211; River Road</li>
                        <li>Kirtland&#8217;s Warbler Habitat Areas</li>
                        <li><a href="https://www.michigan.org/property/lumberman-s-monument-visitor-center">Lumberman&#8217;s Monument</a> &#8211; River Road</li>
                        <li><a href="natural_rivers_ausable_map_338813_7-3.pdf">Map - Au Sable River Natural Rivier System</a></li>
                        <li><a href="http://www.michiganausablevalleyrailroad.com/">Michigan Au Sable River Valley Railroad</a> &#8211; Fairview</li>
                        <li><a href="http://www.skyline.events/">Skyline Events Center</a> - Comins</li>
                        <li><a href="https://www.michigan.org/property/steiner-museum">Steiner Museum</a> &#8211; Fairview</li>
                        <li><a href="https://www.michigan.org/property/sturgeon-point-lighthouse">Sturgeon Point Lighthouse</a> &#8211; Harrisville</li>
                        <li><a href="https://www.michigan.org/property/tawas-point-lighthouse">Tawas Point Lighthouse</a> &#8211; East Tawas</li>
                        <li>Warbler Monument - Mio</li>
                        <li><a href="http://wurtsmithairmuseum.net/">Wurtsmith Air Museum</a> &#8211; Oscoda</li>
                      </ul>
                      <h3><b><span style="font-weight: bold;">Canoe and Kayak Rentals</span></b></h3>
                      <ul>
                        <li><a href="http://alconacanoerental.com/">Alcona Canoe Rental </a>&#8211; Glennie</li>
                        <li><a href="http://wwwbearpawcabinsandcanoes.com/">Bear Paw Cabins &amp; Canoes </a>- Luzerne</li>
                        <li><a href="http://gottslanding.com/">Gotts&#8217; Landing Canoe Livery </a>&#8211; Mio</li>
                        <li><a href="http://hinchman.com/">Hinchman&#8217;s Acres </a>&#8211; Mio</li>
                        <li><a href="http://www.luzerneexpresscampground.com/">Luzerne Express Campground </a>- Luzerne</li>
                        <li><a href="http://rainbowresortmio.com/">Rainbow Resort &amp; Canoe Rental</a> - Mio</li>
                        <li><a href="http://rollwayresort.com/">Rollway Resort&nbsp; &amp; Canoe Rental</a> &#8211; Hale</li>
                      </ul>
                      <h3>Churches</h3>
                      <ul>
                        <li>Calvary Baptist Church - Mio</li>
                        <li>Church of Christ in Mio - Mio</li>
                        <li>Comins Community Church - Comins</li>
                        <li>Emma Lowery United Church of Christ - Luzerne</li>
                        <li>Fairview Mennonite Church - Fairview</li>
                        <li>Faith Baptist Church - Fairview </li>
                        <li>Freedom Worship Center Church - Mio</li>
                        <li>Luzerne Baptist Church - Luzerne</li>
                        <li>Living Water Lutheran Church - Mio</li>
                        <li>Mio Baptist Church - Mio</li>
                        <li>Mio Seven Day Adventist Church - Mio</li>
                        <li>Mio United Methodist Church - Mio</li>
                        <li>New Hope Assembly of God Church - Mio</li>
                        <li>Praxis Church - Mio</li>
                        <li>St. Bartholomew Episcapal Church - Mio</li>
                        <li>St. Johns Evangelical Lutheran Church - Mio</li>
                        <li>St. Mary&#8217;s Catholic Church - Mio</li>
                        <li>St. Mina Coptic Orthadox Patriarch Retreat Center - Mio</li>
                        <li>TriPoint Church of God - Mio</li>
                      </ul>
                      <h3>&nbsp;</h3>
                      <h3 style="margin-bottom: 0px;">&nbsp;</h3>
                    </td>
                    <td valign="top" id="Cell27">
                      <h3>Clubs &amp; Organizations</h3>
                      <ul>
                        <li>Comins Eagles #3544 and Auxiliary - Comins</li>
                        <li>Fairview/Comins Lions Club - Fairview</li>
                        <li>Kights of Columbus - Mio</li>
                        <li>Luzerne American Legion Post #162 - Luzerne</li>
                        <li>Mio Lions Club - Mio</li>
                        <li>Mio Masonic Lodge 596 - Mio</li>
                        <li>Mio Moose Lodge #1035 and Women of the Moose Chapter #68 - Mio</li>
                        <li>Oscoda County Shriners Club - Mio</li>
                        <li>VFW Post 4126 - Mio</li>
                      </ul>
                      <h3>County Fairs</h3>
                      <ul>
                        <li><a href="http://alconacountyfair.com/">Alcona County Fair</a> &#8211; Lincoln &#8211; August 15-19</li>
                        <li><a href="http://www.ioscocountyfair.com/">Iosco County Fair</a> &#8211; Hale &#8211; July 25-30</li>
                        <li><a href="http://ogemawcountyfair.org/">Ogemaw County Fair</a> &#8211; West Branch &#8211; August 15-20</li>
                        <li><a href="https://www.oscodacountymi.com/fairgrounds/">Oscoda County Fair</a> &#8211; Mio &#8211; Jul 24-29</li>
                      </ul>
                      <h3><b><span style="font-weight: bold;">Fishing</span></b></h3>
                      <ul>
                        <li><a href="Michigan-Inland-Trout---Salmon-Designated-Trout-Stream-Map---Lower-Peninsula.pdf">Michigan Inland Trout &amp; Salmon Designated Trout Stream Map -(Lower Peninsula</a></li>
                        <li><a href="http://michiganstreamside.com/index.htm">Streamside Custom Rod &amp; Guide Service</a></li>
                        <li><a href="http://trophywatersflyshop.net/">Trophy Waters Fly Shop</a> - Mio</li>
                      </ul>
                      <h3>Gas Stations</h3>
                      <ul>
                        <li><a href="https://www.facebook.com/pages/Blairs-Service/126255120761561">Blair&#8217;s Service</a>- Mio</li>
                      </ul>
                      <h3>Golf Courses</h3>
                      <ul>
                        <li><a href="http://cedarvalleygolfclub.net/">Cedar Valley Golf Club</a> - Comins</li>
                        <li><a href="http://golfglennie.com/">Eagle Ridge Golf Club</a> - Glennie</li>
                        <li><a href="http://www.fairviewhillsgolfclub.com/">Fairview Hills Golf Club</a> - Mio</li>
                        <li><a href="http://garlandusa..com/">Garland Lodge &amp; Golf Resort</a> - Lewiston</li>
                        <li><a href="http://www.wickerhillsgolf.com/">Wicker Hills Golf Course</a> - Hale</li>
                      </ul>
                      <h3>Groceries</h3>
                      <ul>
                        <li><a href="https://www.facebook.com/MrBillsMarket">Mr. Bill&#8217;s Market </a>- Fairview</li>
                        <li><a href="http://fairviewfoodmarket.com/">Fairview Food Market</a> - Fairview</li>
                        <li><a href="http://www.familyfare.spartanstores.com/">Family Fare</a> - Mio<b><span style="font-weight: bold;"></span></b></li>
                      </ul>
                      <h3>Hiking &amp; Ski Trails Maps</h3>
                      <ul>
                        <li><a href="Map---Corsair-Trails.gif">Map - Corsair Trails</a></li>
                        <li><a href="Map---Eagle-Run-Trails.gif">Map - Eagle Run Trails</a></li>
                        <li><a href="Map---Highbanks-Trails.gif">Map - Highbanks Trails</a></li>
                        <li><a href="Map---Hoist-Lakes-Foot-Travel-Area.gif">Map - Hoist Lakes Foot Travel Area</a></li>
                        <li><a href="Map---Reid-Lake-Foot-Travel-Area.gif">Map - Reid Lake Foot Travel Area</a></li>
                        <li><a href="Map---Wakely-Lake-Foot-Travel.pdf">Map - Wakely Lake Foot Travel</a></li>
                        <li><a href="Map---Wolf-Creek-Trail.pdf">Map - Wolf Creek Trail</a></li>
                      </ul>
                      <h3 style="margin-bottom: 0px;">&nbsp;</h3>
                    </td>
                    <td valign="top" id="Cell26">
                      <h3><b><span style="font-weight: bold;">Oscoda County Lakes &amp; Streams</span></b></h3>
                      <ul>
                        <li><a href="natural_rivers_ausable_map_338813_7-3.pdf">Au Sable River</a> - </li>
                        <ul>
                          <li>Access at Joseph Pillars Park - ADA compliant</li>
                          <li>Access at Parmalle Bridge, County Road 489, Luzerne - Canoe Launch, Rustic Campground</li>
                          <li>Access at Big Creek Tw[. Deeter Road, Luzerne - Canoe Launch, Picnic Area, Pavillion, Rustic Camping</li>
                          <li>Access at Camp 10 Bridge Road - Boat Launch, Fishing Piers</li>
                        </ul>
                        <li><a href="BASS_LAKE.pdf">Bass Lake</a> - Boat Ramp, Picnic Area, Swimming</li>
                        <li>Big Creek - Fly Fishing, Fishing</li>
                        <li><a href="ISLAND_LAKE.pdf">Island Lake</a> - Boating, Camping, Picnic Area, Swimming</li>
                        <li><a href="MACK_LAKE.pdf">Mack Lake</a> - Boating, Fishing, Rustic Camping</li>
                        <li><a href="MCCOLLUM_LAKE.pdf">McCullum Lake</a> - Boat Launch, Fishing, Rustic Camping, Swimming</li>
                        <li><a href="MIO_POND.pdf">Mio Pond </a>- </li>
                        <ul>
                          <li>Access at Oscoda County Park, Jay Smith Drive, Mio - Horseshoe Pits, Volley Ball Courts, Pavillion, Rustc &amp; Improved Campsites</li>
                          <li>Access at the end of Pond Drive, Mio - Boat Launch, Camping, Fishing Piers, Picnic Area</li>
                          <li>Access at end of Gerber Road, Mio - Picnic Area, Rustic Camping, Swimming</li>
                          <li>Access at Camp 10 Bridge Road, Mio - Boat Launch, Fishing Piers, Picnic Area</li>
                        </ul>
                        <li><a href="MUSKRAT_LAKE.pdf">Muskrat Lake </a>- Boating, Fishing, Rustic Camping, Picnic Area, Swimming, </li>
                        <li>Perry Lakes (<a href="SOUTH_AND_MIDDLE_PERRY_LAKES.pdf">Middle &amp; South Perry Lakes</a>, <a href="NORTH_PERRY_LAKE.pdf">North Perry Lake</a>)_- Boat Launch, Day Park with Pavillion, Swimming</li>
                        <li><a href="SMITH_LAKE.pdf">Smith Lake</a> - County Day Park, :Picnic Area with Pavilion, Playground, Swimming.</li>
                        <li><a href="TEA_LAKE.pdf">Tea Lake</a>- Boat Dock &amp; Ramp</li>
                        <li><a href="WAGNER_LAKE_TWO_BIT_LAKE.pdf">Wagner Lake</a> - Fishing, Rustic Camping, Swimming</li>
                      </ul>
                      <h3>Restaurants and Bars</h3>
                      <ul>
                        <li><a href="http://www.ausableriverrestaurant.com/">Au Sable River Restaurant</a> - Mio</li>
                        <li><a href="https://www.facebook.com/deezpizzeria/">Deez Pizzeria </a>- Luzerne</li>
                        <li><a href="https://www.facebook.com/Djs-Scenic-487971694595556/">DJ&#8217;s Scenic Bar &amp; Restaurant</a> - McKinley</li>
                        <li><a href="https://www.facebook.com/Ellies-12-Tables-192095661195135/">Ellie&#8217;s 12 Tables</a> - Mio</li>
                        <li><a href="https://www.fairviewfamilyrestaurant.com/">Fairview Family Restaurant &amp; Coffee Shop</a> - Fairview</li>
                        <li><a href="http://garlandusa..com/">Garlland Lodge &amp; Golf Resort</a> - Lewiston</li>
                        <li><a href="https://www.facebook.com/pages/Lost-Creek-Skyranch/174643605942330">Lost Creek Skyranch</a> -Luzerne</li>
                        <li><a href="http://www.luzerneexpresscampground.com/">Luzerne Express Campground </a>- Luzerne</li>
                        <li><a href="https://www.facebook.com/MA-Deeters-Inc-114941411857726/">Ma Deeter&#8217;s Restaurant &amp; Tavern</a> - Luzerne</li>
                        <li>McDonald&#8217;s Family Restaurant - Mio</li>
                        <li><a href="http://miopizzashop.com/">Mio Pizza Shop</a> - Mio</li>
                        <li>Mio Saloon - Mio</li>
                        <li><a href="https://www.facebook.com/pages/OBriens-Restaurant/156641591041973">O&#8217;Brien&#8217;s Restaurant </a>- Mio</li>
                        <li><a href="https://www.facebook.com/ThePaddleInn/">Paddle Inn</a> - Mio</li>
                        <li><a href="https://www.facebook.com/ScootersWildTurkeySaloon/?rf=103935606366179">Scooter&#8217;s Wild Turkey Saloon </a>- Fairview</li>
                        <li><a href="https://www.facebook.com/The-Lunchbox-of-Curran-201182373734984/">The Lunchbox</a> - Curran</li>
                        <li><a href="https://www.facebook.com/WalkersBarMioMI/">Walkers&#8217; Bar &amp; Restaurant</a> - Mil</li>
                      </ul>
                      <h3>Snowmoblile &amp; ORV Trail System</h3>
                      <ul>
                        <li><a href="https://www.facebook.com/ausablevalleysnowgroomersmio/">Au Sable Valley Snow Groomer, Inc.</a> </li>
                        <li><a href="Bull_Gap_Trail.pdf">Map - Bull Gap HIll Climb Area</a></li>
                        <li><a href="northern-_lp_snowmobile.pdf">Map - Designated Snowmobile Trails</a></li>
                        <li><a href="the_meadows_trail_north.pdf">Map - The Meadows Trail North</a></li>
                        <li><a href="http://www.msasnow.org/">Snowmobile Trail Reports</a></li>
                      </ul>
                      <h3>Wineries &amp; Vineyards</h3>
                      <ul>
                        <li><a href="http://www.rosevalleywinery.net/#!/">Rose Valley Winery</a> - Rose City</li>
                        <li><a href="https://www.facebook.com/valleymisttastingroom">Vally Mist Vineyards</a> - Rose City </li>
                      </ul>
                      <h3 style="margin-bottom: 0px;">&nbsp;</h3>
                    </td>
                  </tr>
                  
          </table>
          

  </div>
</body>
</html>
      `,
      slides: [
        {
          image: require("@/assets/Au-Sable-Highbanks-Overlook.jpg"),
          description: "AuSable Highbanks Overlook",
        },
        {
          image: require("@/assets/Au-Sable-River-Queen.jpg"),
          description: "AuSable River Queen",
        },
        {
          image: require("@/assets/Lumberman-s-Monument.jpg"),
          description: "Lumberman's Monument",
        },
        {
          image: require("@/assets/Sturgeon-Point-Lighthouse.jpg"),
          description: "Sturgeon Point Lighthouse",
        },
        {
          image: require("@/assets/Iargo-Springs.jpg"),
          description: "Iargo Springs",
        },
        {
          image: require("@/assets/Civil-War-Reenactment.jpg"),
          description: "Civil War Reenactment",
        },
        {
          image: require("@/assets/Blazing-Star-Wildflower.jpg"),
          description: "Blazing Star Wildflower",
        },
        {
          image: require("@/assets/White-tail-Deer.jpg"),
          description: "White-tail Deer",
        },
        {
          image: require("@/assets/Chanterelle-Mushrooms.jpg"),
          description: "Chanterelle Mushrooms",
        },
        {
          image: require("@/assets/Porcupine.jpg"),
          description: "Porcupine",
        },
        {
          image: require("@/assets/Piliated-Woodpecker.jpg"),
          description: "Piliated Woodpecker",
        },
        {
          image: require("@/assets/Snapping-Turtle.jpg"),
          description: "Snapping Turtle",
        },
        {
          image: require("@/assets/Yellow-Ladyslipper-Orchid.jpg"),
          description: "Yellow Ladyslipper Orchid",
        },
        {
          image: require("@/assets/Wild-Turkey.jpg"),
          description: "Wild Turkey",
        },
      ],

      //   sections: [
      //     {
      //       title: "Amish Community Businesses",
      //       rows: [
      //         ["Country Corners Bulk Food", "Highland Lumber"],
      //         ["Country Corners Greenhouse", "Home Style Bakery"],
      //         ["Country Feed Supply", "Maple Ridge Metal"],
      //         ["Miller Brothers Maple Products", "Mio Buggy Shop"],
      //         ["Nature's Beauty Rustic Store", "North Star Storage Buildings"],
      //         ["Oak Hill Furniture & Fabric", "Sensenig’s Repair"],
      //         ["Pine Grove Woodworking", "Shady Lane Footware"],
      //         ["The Bakery", "Sweet Success Sugarbush"],
      //         ["The Pioneer Store", "Triple Heritage Farm"],
      //       ],
      //     },
      //     { title: "Attractions", rows: [["a", "d"]] },
      //   ],
    };
  },
};
</script>